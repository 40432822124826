.container {
    cursor: pointer;
    overflow: hidden;
}

.desktop {
    composes: container;
}

.mobile {
    composes: container;
}

.button {
    cursor:pointer;
    display:block;
    border-radius: 10px;
}

.spacer {
    height: 1px;
    background-color: #d0d0d0;
    width: 70%;
    margin: 10px auto;
}

.container img {
    width: 100%;
    height: 100%;
    margin:auto;
}

.container img:active {
    background-color:#bae6ef;
}

@media screen and (max-width: 600px) {
    .desktop {
        bottom:100px;
    }
}
.location {
    cursor: pointer;
    overflow: hidden;
    border-radius: 50px;
}

.location:active {
    background-color:#bae6ef;
}

.desktop {
    composes: location;
}

.mobile {
    composes: location;
}


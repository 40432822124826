.mapWrapper {
    height: 100%
}

.map {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}


@media only screen and (max-device-width: 768px)  {
    .map {
        bottom: 0rem;
        transition: bottom 0.3s ease;
    }
    .map.resizing {
        transition: none;
    }
}

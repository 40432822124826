.container {
    position:absolute;
    left:20px;
    top:55px;
    width:350px;
    z-index:100;
    overflow: hidden;
    background-color:white;
    border-radius: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}
.text {
    margin: 10px 0;
    padding: 0 20px;
    font-size: 1.2em;
}

.topcontainer {
    height: 5rem;
    background-color:#ff8200;
}

.mobiletopcontainer {
    height: 4rem;
    background-color: #ff8200;
    box-shadow: 0 2px 4px rgba(0,0,0,.3);

}

.mobilemiddlecontainer {
    height: 0;
    overflow-y: hidden;
    transition: all 0.25s ease-in-out;
    opacity: 0;
}
.height{
    height: 10rem;
}
.middlecontainer {
    color:#000;
    background-color:#fff;
    padding: 2.5rem 0 1rem 0;
    opacity: 1;
    overflow:auto;
    transition: all 0.30s ease-in-out;
    text-align: left;

    position: fixed;
    top: 0;
    transform: translateY(20%);

    width: 80vw;
    left: 10vw;

    box-shadow: 0px 0px 69px 24px rgba(0,0,0,0.47);
}

.closed {
    opacity: 0;

    transform: translateY(10%);

    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);

    pointer-events: none;
}

.mobileimagelogo {
    position: absolute;
    max-width: 100px;
    height: 50px;
    left: 3rem;
    padding: calc((4rem - 50px)/2) 0;
}

.imagelogo {
    width: 7rem;
    height: 5rem;
}

.desktop {
    composes: container;
    left: 20px;
    top: 55px;
}

.mobile {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 100;
    overflow:hidden;
    border-radius: 0;
    background-color: white;
    top: 0;
}

.contents {
    text-align: left;
    padding: 3rem 2rem;
    height:100%;
    z-index:1499;
    position:fixed;
    top:0;
    background-color:white;
    transition: left 0.3s ease-in-out, right 0.3s ease-in-out;
    word-break: break-word;
    overflow: auto;
    left:100%;
}

ul {
    list-style: none;
    padding-left:1rem;
}
li {
    margin-bottom:1.5rem;
}
li::before{
    content: "•";
    color: #ff8200;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.contents a {
    color: #009dd4;
    font-size: 18px;
    text-decoration: none;
}

.contents.mobile  {
    width: 85%;
}
.contents.expanded.mobile  {
    left:15%;
    display:block;
    box-shadow: -2px 0 4px rgba(0,0,0,0.2), -1px 0 0 rgba(0,0,0,0.02);
}

.infoContainer {
    position: relative;
    line-height: 0.95em;
    top: 50%;
    float:right;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    display: inline-block;
    cursor: pointer;
    padding: 6px 6px;
    font-size: 2.5em;
    color:#ff8200;
    background-color: #fff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    font-family: sans-serif;
}
.infoContainer:active{
    background-color: rgba(255,255,255,0.5);
}

.contents h1 {
    font-family: "FuturaEF-DemiBold", sans-serif;
    color: #ff8300;
}

.contents h2 {
    margin-top:2rem;
}

.hide {
    display: none;
}

.button {
    font-size: 1.5em;
    width: 10rem;
    height: 3rem;
    bottom: 2rem;
    left: 50%;
    border: none;
    color: #fff;
    background-color: #ff8200;
    transition-duration: 0.2s;
    padding: 10px 12px;
    outline: none;
    margin-top: 1rem;
}


.container {
    width: 100%;
    height: 9.5rem;
    bottom: 0;
}
.text {
    font-size: 1.5em;
}
.overlay {
    width: 100%;
    height: calc(100vh - 4rem);
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    opacity: 1;
}
.container > *{
    display: block;
    margin: 0;
}
.containerOverlay{
    height: calc(100vh - 4rem);
    width: 100%;
}

.hide{
    height: 0;
    width: 0;
    display: none;
    opacity: 0;
}
.button {
    font-size: 1.5em;
    width: 10rem;
    height: 3rem;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    border: none;
    transform: translateX(-50%);
    color: #fff;
    background-color: #ff8200;
    transition-duration: 0.2s;
    padding: 10px 12px;
    outline: none;
}

.button:active {
    background-color: #ff8200;
    box-shadow: 0 2px 4px rgba(0,0,0,.3);
    color: #fff;
}
.button::-moz-focus-inner{
    border: none;

}
.code{
    text-transform: uppercase;
    width: fit-content;
    font-size: 1.7em;
    margin: auto;
    font-family: 'Overpass Mono', sans-serif;
}
.codeContainer{
    position: absolute;
    width: 12rem;
    height: 8rem;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-transform: uppercase;
    font-size: 0.7em;
    background-color: rgba(255,255,255,0.9);
}
.mobilecode{
    padding: 0;
    margin: 0;
    font-size: 3em;
    font-family: 'Overpass Mono', sans-serif;
}



.container{
    position: absolute;
    width: 4rem;
    bottom: 50%;
    right: 10%;
    transform: translate(50%, 50%);
    border-radius: 50px;
    overflow: hidden;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 2px 4px rgba(0,0,0,.3);
}
.navMobile{
    composes: container;
}
.navMobile > div{
    padding: 7px;
    margin: 4px 2px;
}
.nav{
    composes: container;
}
.nav > div{
    padding: 7px;
    margin: 4px 2px;
}
@font-face{font-family:FuturaEF-DemiBold;src:url(./fonts/FuturaEF-DemiBold.woff) format("woff");font-weight:600;font-display:auto}
@font-face{font-family:FuturaEF-Book;src:url(./fonts/FuturaEF-Book.woff) format("woff");font-display:auto}
@font-face {
  font-family: 'Overpass Mono';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/overpass-mono-v4-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Overpass Mono SemiBold'), local('OverpassMono-SemiBold'),
  url('./fonts/overpass-mono-v4-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/overpass-mono-v4-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/overpass-mono-v4-latin-600.woff') format('woff'), /* Modern Browsers */
  url('./fonts/overpass-mono-v4-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/overpass-mono-v4-latin-600.svg#OverpassMono') format('svg'); /* Legacy iOS */
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "FuturaEF-Book", sans-serif;
  font-size:16px;
  -webkit-font-smoothing: antialiased;
}
* {
  font-family: "FuturaEF-Book", sans-serif;
}
html {
  -webkit-tap-highlight-color: transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
